import { lazy } from 'react';
import FreeLayout from 'layout/freeLayout';

// project imports
import Loadable from 'ui-component/loadable';

// login option 3 routing

const VMConsole = Loadable(lazy(() => import('views/virtualMachines/console/console')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const Free = {
    path: '/',
    element: <FreeLayout />,
    children: [
        {
            path: 'virtual_machines/:name/console',
            element: <VMConsole />,
            exact: true
        }
    ]
};

export default Free;
