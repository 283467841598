// project imports

import { CREDS_FETCH, LOADING_SCREEN, USER_INFO } from 'store/actions/authAction';

// action - state management

export const initialState = {
    user_info: {},
    loading_screen: false,
    credsFetch: false
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                user_info: action.payload
            };
        case LOADING_SCREEN:
            return {
                ...state,
                loading_screen: false
            };
        case CREDS_FETCH:
            return {
                ...state,
                credsFetch: action.payload
            };

        default:
            return state;
    }
};

export default authReducer;
