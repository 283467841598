import { Box, CircularProgress, Typography } from '@mui/material';

import CircleBlur from './animation/circleBlur';
import { getLogoLoading } from 'helper/getLogo';
import { getAppName } from 'helper/appName';
import { getBgAuth } from 'helper/background';

const LoadingScreen = () => {
    const logoPath = getLogoLoading();
    return (
        <Box
            display="flex"
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
                background: `url(${getBgAuth()})`,
                position: 'fixed',
                width: '100%',
                height: '100vh',
                top: '0',
                right: '0',
                zIndex: '99999',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <CircleBlur />
            <Box sx={{ display: 'grid', placeItems: 'center', zIndex: 999 }}>
                {logoPath ? (
                    <img src={logoPath} alt="" />
                ) : (
                    <Typography fontSize="32px" fontWeight={'bold'} color="white" textTransform="uppercase">
                        {getAppName()}
                    </Typography>
                )}
                <Box mt={2}>
                    <CircularProgress size={30} sx={{ color: '#ffff' }} />
                </Box>
            </Box>
        </Box>
    );
};

export default LoadingScreen;
