import { lazy } from 'react';

// project imports
import MainLayout from 'layout/mainLayout';
import Loadable from 'ui-component/loadable';
import { Navigate } from 'react-router';

//main menu
const Customer = Loadable(lazy(() => import('views/customer')));
const CustomerDetail = Loadable(lazy(() => import('views/customer/detail/index')));
const ComposeEmail = Loadable(lazy(() => import('views/composeEmail')));
const CreateComposeEmail = Loadable(lazy(() => import('views/composeEmail/createComposeEmail/createComposeEmail')));
const DetailComposeEmail = Loadable(lazy(() => import('views/composeEmail/detailComposeEmail')));
const Admin = Loadable(lazy(() => import('views/admin')));
const Helpdesk = Loadable(lazy(() => import('views/helpdesk')));
const HelpdeskDetail = Loadable(lazy(() => import('views/helpdesk/detailTicket')));
const OperatingSystem = Loadable(lazy(() => import('views/operatingSystem')));
const CreateOperatingSystem = Loadable(lazy(() => import('views/operatingSystem/createOs')));
const DetailOperatingSystem = Loadable(lazy(() => import('views/operatingSystem/detail')));
const CreateTemplate = Loadable(lazy(() => import('views/operatingSystem/createTemplate')));
const IpPool = Loadable(lazy(() => import('views/ipPool')));
const CreateIpPool = Loadable(lazy(() => import('views/ipPool/createIpPool')));
const IpPoolDetail = Loadable(lazy(() => import('views/ipPool/detail')));
const CreateIpAddress = Loadable(lazy(() => import('views/ipPool/createIpAddress')));
const Location = Loadable(lazy(() => import('views/location')));
const CreateLocation = Loadable(lazy(() => import('views/location/createLocation')));
const LocationDetail = Loadable(lazy(() => import('views/location/detail')));
const CreateNode = Loadable(lazy(() => import('views/location/node/createNode')));
const Pricing = Loadable(lazy(() => import('views/pricing')));
const SettingApp = Loadable(lazy(() => import('views/settings')));
const Log = Loadable(lazy(() => import('views/log')));
const VirtualMachines = Loadable(lazy(() => import('views/virtualMachines')));
const VirtualMachinesDetail = Loadable(lazy(() => import('views/virtualMachines/detail')));
const Profile = Loadable(lazy(() => import('views/profile')));
const Dashboard = Loadable(lazy(() => import('views/overview')));
const Billing = Loadable(lazy(() => import('views/billing')));
const BillingReportDetail = Loadable(lazy(() => import('views/billing/reports/detail/detail')));
const RedeemCode = Loadable(lazy(() => import('views/redeemCode')));
const Network = Loadable(lazy(() => import('views/network')));
const BlockStorage = Loadable(lazy(() => import('views/storage/blockStorage')));
const BlockStorageDetail = Loadable(lazy(() => import('views/storage/blockStorage/detail')));

// ==============================|| MAIN ROUTING ||============================== //
const userRole = JSON.parse(localStorage.getItem('user_info'))?.roles ?? null;

function roleAllowed(userRole, allowedRoles) {
    return allowedRoles.includes(userRole);
}

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <Dashboard /> : <Navigate to="/customer" replace />
        },
        {
            path: 'admin',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <Admin /> : <Navigate to="/" replace />
        },
        {
            path: 'customer',
            element: <Customer />
        },
        {
            path: 'customer/detail/:id',
            element: <CustomerDetail />
        },
        {
            path: 'compose-email',
            element: <ComposeEmail />
        },
        {
            path: 'compose-email/create',
            element: <CreateComposeEmail />
        },
        {
            path: 'compose-email/detail/:id',
            element: <DetailComposeEmail />
        },
        {
            path: 'pricing',
            element: roleAllowed(userRole, ['Super Admin']) ? <Pricing /> : <Navigate to="/" replace />
        },
        {
            path: 'location',
            element: roleAllowed(userRole, ['Super Admin']) ? <Location /> : <Navigate to="/" replace />
        },
        {
            path: 'location/create',
            element: roleAllowed(userRole, ['Super Admin']) ? <CreateLocation /> : <Navigate to="/" replace />
        },
        {
            path: 'location/detail/:id',
            element: roleAllowed(userRole, ['Super Admin']) ? <LocationDetail /> : <Navigate to="/" replace />
        },
        {
            path: 'location/detail/node/create',
            element: roleAllowed(userRole, ['Super Admin']) ? <CreateNode /> : <Navigate to="/" replace />
        },
        {
            path: 'ip-pool',
            element: roleAllowed(userRole, ['Super Admin']) ? <IpPool /> : <Navigate to="/" replace />
        },
        {
            path: 'ip-pool/create',
            element: roleAllowed(userRole, ['Super Admin']) ? <CreateIpPool /> : <Navigate to="/" replace />
        },
        {
            path: 'ip-pool/ip-address/create',
            element: roleAllowed(userRole, ['Super Admin']) ? <CreateIpAddress /> : <Navigate to="/" replace />
        },
        {
            path: 'ip-pool/detail/:id',
            element: roleAllowed(userRole, ['Super Admin']) ? <IpPoolDetail /> : <Navigate to="/" replace />
        },
        {
            path: 'operating-system',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <OperatingSystem /> : <Navigate to="/" replace />
        },
        {
            path: 'operating-system/create',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <CreateOperatingSystem /> : <Navigate to="/" replace />
        },
        {
            path: 'operating-system/template/create',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <CreateTemplate /> : <Navigate to="/" replace />
        },
        {
            path: 'operating-system/detail/:id',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <DetailOperatingSystem /> : <Navigate to="/" replace />
        },
        {
            path: 'helpdesk',
            element: <Helpdesk />
        },
        {
            path: 'helpdesk/:token',
            element: <HelpdeskDetail />
        },
        {
            path: 'app-setting',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Billing']) ? (
                <SettingApp />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'log',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <Log /> : <Navigate to="/" replace />
        },
        {
            path: 'virtual_machines',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <VirtualMachines />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'virtual_machines',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <VirtualMachines />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'virtual_machines/:name',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <VirtualMachinesDetail />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'network',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <Network />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'redeem-code',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Billing']) ? (
                <RedeemCode />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'dashboard',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <Dashboard /> : <Navigate to="/customer" replace />
        },
        {
            path: 'block-storage',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <BlockStorage />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'block-storage/detail/:id',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <BlockStorageDetail />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'billing',
            element: <Billing />
        },
        {
            path: 'billing/reports/detail/:id',
            element: <BillingReportDetail />
        }
    ]
};

export default MainRoutes;
