import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import AuthService from 'service/auth.service';

export const ComponentWithEffect = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (JSON.parse(localStorage.getItem('regis')) === false) {
            navigate('/complete-registration');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        dispatch({ type: 'CREDS_FETCH', payload: false });
        dispatch({ type: 'ACCESS', payload: false });
        const checkAuthentication = async () => {
            try {
                if (localStorage.getItem('user_info')) {
                    // Delay for 5 seconds if user variable is not empty
                    const res = await AuthService.checkAuth();
                    dispatch({ type: 'USER_INFO', payload: res.data });
                    localStorage.setItem('user_info', JSON.stringify(res.data));
                    dispatch({ type: 'CREDS_FETCH', payload: true });
                    dispatch({ type: 'TOTAL_UNREAD', payload: res?.data?.total_notifications });
                    dispatch({ type: 'ALL_NOTIFICATION', payload: res?.data?.partial_notifications });
                    dispatch({ type: 'ACCESS', payload: true });
                }
            } catch (error) {
                dispatch({ type: 'ACCESS', payload: false });
            }
        };

        checkAuthentication();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, dispatch]);
};
