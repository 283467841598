import api from './api';
import TokenService from './token.service';

const register = (username, email, password) => {
    return api.post('/backoffice/register', {
        username,
        email,
        password
    });
};

const login = (username, password) => {
    return api
        .post('/backoffice/auth/login', {
            username,
            password
        })
        .then((response) => {
            if (response.data) {
                TokenService.setUser(response.data);
            }

            return response.data;
        });
};

const checkAuth = () => {
    return api.get('/backoffice/inf').then((response) => {
        return response.data;
    });
};

const logout = () => {
    return api.post('/backoffice/auth/logout').then((response) => {
        TokenService.removeUser();
        return response.data;
    });
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user_info'));
};

const refreshToken = () => {
    return api.post('/backoffice/auth/refresh');
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    checkAuth,
    refreshToken
};

export default AuthService;
