// project imports
import {
    DATA_CONSOLE,
    HOSTNAME,
    LOCATION,
    NETWORK,
    OPERATING_SYSTEM,
    OVERVIEW,
    PASSWORD,
    PRICING,
    ROLLBACK,
    SERVER_SIZE,
    SSH_KEY,
    SUMMARY,
    TAB_MENU_DETAIL_VM,
    USERNAME
} from 'store/actions/vmActions';

// action - state management

export const initialState = {
    os: '',
    location: undefined,
    server_size: {
        total_cpu: 1,
        total_disk: 20,
        total_ram: 1
    },
    hostname: '',
    username: '',
    password: '',
    ssh_key: [],
    summary: '',
    dataOverview: '',
    pricing: { ram: 0, cpu: 0, disk: 0 },
    data_console: {},
    network: {
        use_public_ip: true,
        vswitch: null,
        vm_gateway_id: null
    },
    tabMenu: '1',
    rollback: false
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const vmReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPERATING_SYSTEM:
            return {
                ...state,
                os: action.payload
            };
        case LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case SERVER_SIZE:
            return {
                ...state,
                server_size: action.payload
            };
        case HOSTNAME:
            return {
                ...state,
                hostname: action.payload
            };
        case USERNAME:
            return {
                ...state,
                username: action.payload
            };
        case PASSWORD:
            return {
                ...state,
                password: action.payload
            };
        case SSH_KEY:
            return {
                ...state,
                ssh_key: action.payload
            };
        case SUMMARY:
            return {
                ...state,
                summary: action.payload
            };
        case OVERVIEW:
            return {
                ...state,
                dataOverview: action.payload
            };
        case PRICING:
            return {
                ...state,
                pricing: action.payload
            };
        case DATA_CONSOLE:
            return {
                ...state,
                data_console: action.payload
            };
        case NETWORK:
            return {
                ...state,
                network: action.payload
            };
        case TAB_MENU_DETAIL_VM:
            return {
                ...state,
                tabMenu: action.payload
            };
        case ROLLBACK:
            return {
                ...state,
                rollback: action.payload
            };

        default:
            return state;
    }
};

export default vmReducer;
